<!--Sidebar Start-->
<ul class="menuimg_sidebar">
    <a *ngFor="let place of places">
        <li (click)="changePlace(place.moduleName)">
            <div class="{{place.moduleName.toLowerCase()}} placeIcons"
                [ngClass]="[(selectedPlace == place.moduleName.toLowerCase()) ? 'activeIcon' : 'defaultIcon']">
                <mat-icon svgIcon="{{place.moduleName}}"></mat-icon>
                <!-- <img class="iconimg"
                    [src]="'../../assets/images/sidebar/'+place.moduleName.toLowerCase()+'_Icon-'+((selectedPlace == place.moduleName.toLowerCase()) ? 'Active' : 'Primary')+'.svg'"> -->
                <!-- <img class="iconimg onHoverIcon" src="../../assets/images/sidebar/{{place.moduleName.toLowerCase()}}_Icon-Hover.svg">
                <img class="iconimg onActiverIcon" src="../../assets/images/sidebar/{{place.moduleName.toLowerCase()}}_Icon-Active.svg"> -->
            </div>
            <!-- <p>{{place.displayName}}</p> -->
        </li>
    </a>
</ul>
<!--Sidebar Ends-->