<header>
    <div class="headerRow">
        <div class="airaLogo">
            <mat-icon svgIcon="logo"></mat-icon>
            <!-- <img src=" ../../assets/images/logo_512.svg" alt="AIRA Matrix" aria-label="AIRA Matrix" /> -->
        </div>
        <div class="selectedPlace">{{selectedPlace}}</div>
        <div class="headerRigthDiv">
            <div class="loggedinUser">{{userDispName}}</div>
            <div *ngIf="selectedEnv" class="selectedEnvironment">{{selectedEnv}}</div>
            <div class="userProfile">
                <mat-icon svgIcon="userProfile" [matMenuTriggerFor]="menu" #menuTrigger></mat-icon>
                <!-- <img src="../../assets/images/common/user-profile.svg" [matMenuTriggerFor]="menu" #menuTrigger /> -->
                <mat-menu #menu="matMenu">
                    <button *ngIf="isAdmin" mat-menu-item (click)="createUserProfileDialog()">Create User</button>
                    <button mat-menu-item (click)="openUserProfileDialog()">Logout</button>
                </mat-menu>
            </div>
        </div>
    </div>
</header>