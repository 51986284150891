import { AppMaterialModule } from './app-material/app-material.module';
import { AnalysisboardModule } from './analysisboard/analysisboard.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http/';
import { ActionCommentPopupComponent } from './action-comment-popup/action-comment-popup.component';
import { AuthModule } from "./auth/auth.module";
import { AppConfigService } from './app-config.service';
import { IconService } from './icons-setting/Icons.service';
import { CreateUserPopupComponent } from './create-user-popup/create-user-popup.component';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
/* export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
} */

export const appInitializerFn = (configService: AppConfigService) => {
  return () => configService.loadAppConfig();
};

@NgModule({
  declarations: [
    AppComponent,
    ActionCommentPopupComponent,
    CreateUserPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AnalysisboardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    AppMaterialModule
  ],
  entryComponents: [
    ActionCommentPopupComponent
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    IconService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
