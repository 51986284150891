
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http/';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.setHeader(request);
        return next.handle(request).pipe(catchError(error => {
            return throwError(error);
        }));
    }

    private setHeader(request: HttpRequest<any>) {
        let headerObj = {
            setHeaders: {
                'aira_am_access_token': '}z4Y7b-vgUdZ9d`7',
                'Authorization': localStorage.sessionToken ? localStorage.sessionToken : ''
            }
        }
        if (request.url.indexOf('/login') < 0) {
            let userData = JSON.parse(localStorage.userInfo ? localStorage.userInfo : '{}');
            headerObj.setHeaders['X-AD-USER'] = userData.email;
        }
        return request.clone(headerObj);
    }
}