import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalysisboardComponent } from './analysisboard.component';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { RouterModule } from "@angular/router";
import { AnalysisboardRoutingModule } from "./analysisboard-routing.module"
import { AppMaterialModule } from '../app-material/app-material.module';



@NgModule({
  declarations: [
    AnalysisboardComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AnalysisboardRoutingModule,
    AppMaterialModule
  ]
})
export class AnalysisboardModule { }
