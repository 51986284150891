<div class="createUserMainDiv">
    <div class="createUserHeaderDiv">
        <div class="createUserHeaderText">Create User</div>
        <div class="closeCreateUserPopup"></div>
    </div>
    <div class="createUserBodyDiv">
        <div class="userEmail">
            <mat-form-field appearance="outline" class="createUserFormDiv">
                <mat-label>Email</mat-label>
                <input type="email" matInput [(ngModel)]="createUserData.email" placeholder="Enter Email">
            </mat-form-field>
        </div>
        <div class="userName">
            <mat-form-field appearance="outline" class="createUserFormDiv firstName">
                <mat-label>First Name</mat-label>
                <input matInput [(ngModel)]="createUserData.firstname" placeholder="Enter First Name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="createUserFormDiv lastName">
                <mat-label>Last Name</mat-label>
                <input matInput [(ngModel)]="createUserData.lastname" placeholder="Enter Last Name">
            </mat-form-field>
        </div>
        <div class="userPassword">
            <mat-form-field appearance="outline" class="createUserFormDiv">
                <mat-label>Password</mat-label>
                <input type="password" matInput [(ngModel)]="createUserData.password" placeholder="Enter Password">
            </mat-form-field>
        </div>
        <div class="userRole">
            <mat-form-field appearance="outline" class="createUserFormDiv">
                <mat-label>Role</mat-label>
                <mat-select [(value)]="createUserData.role">
                    <mat-option value="ADMIN">Admin</mat-option>
                    <mat-option value="REGULAR">Regular</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="userCompany">
            <mat-form-field appearance="outline" class="createUserFormDiv">
                <mat-label>Company</mat-label>
                <input matInput [(ngModel)]="createUserData.company" placeholder="Enter Company">
            </mat-form-field>
        </div>
        <div class="userPurpose">
            <mat-form-field appearance="outline" class="createUserFormDiv">
                <mat-label>Purpose</mat-label>
                <textarea matInput [(ngModel)]="createUserData.purpose" placeholder="Enter Purpose"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="createUserFooterDiv">
        <button mat-flat-button class="cancelBtn" (click)="closeUserDialog()">Close</button>
        <button mat-flat-button class="createUserBtn" (click)="createUser()">Create User</button>
    </div>
</div>