import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
import { CreateUserPopupComponent } from '../create-user-popup/create-user-popup.component';
import { AppConfigService } from './../app-config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  location: any = {
    analysis: 'Analysis Dashboard',
    users: 'Users'
  };
  selectedPlace: string;
  selectedEnv: string;
  isAdmin: boolean = false;
  userDispName: string = '';

  constructor(private router: Router, private commonService: CommonService, public dialog: MatDialog, private configService: AppConfigService) {
    this.selectedEnv = configService.config.envLabel;
    let userData = JSON.parse(localStorage.userInfo ? localStorage.userInfo : '{}');
    this.userDispName = `Hi ${userData.firstname}  ${userData.lastname}`;
    if (userData.userClass && (userData.userClass == 'ADMIN')) {
      this.isAdmin = true;
    }
  }

  ngOnInit() {
    this.commonService.currentPlace.subscribe((value) => {
      this.selectedPlace = this.location[value] ? this.location[value] : '';
    });
  }

  openUserProfileDialog() {
    this.router.navigate(['login']);
  }

  createUserProfileDialog() {
    const dialogRef = this.dialog.open(CreateUserPopupComponent, {
      width: '25vw',
      disableClose: true,
      data: {}
    });
  }

}
