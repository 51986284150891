import { Icons } from './icons';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  appConfig: any;
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer, private configService: AppConfigService) {
    this.appConfig = configService.config;
  }

  registerIcons() {
    this.load(Icons, '../' + this.appConfig.appBaseName + 'assets/images/svg')
  }

  private load(icons: any, url: string) {
    Object.keys(icons).forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${url}/${icons[icon]}.svg`)
      )
    });
  }
}