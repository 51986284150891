import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl: string = 'http://172.28.42.149:9393/';

  private selectedLocation = new BehaviorSubject('');
  currentPlace = this.selectedLocation.asObservable();

  private bsReloadUserList = new BehaviorSubject(false);
  obsReloadUserList = this.bsReloadUserList.asObservable();

  appConfig: any = {};

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.appConfig = configService.config;
  }

  setCurrentPlace(place: string) {
    this.selectedLocation.next(place);
  }

  reloadUserList(doReload: boolean = false) {
    this.bsReloadUserList.next(doReload);
  }

  login(userCredentials) {
    return this.http.post(`${this.appConfig.apiUrl}airavat-java/v1/admin/login`, userCredentials);
  }

  createUser(newUserData) {
    return this.http.post(`${this.appConfig.apiUrl}airavat-java/v1/admin/create-user`, newUserData);
  }


}
