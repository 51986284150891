import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {
  places: any = [
    {
      moduleName: 'analysis',
      displayName: 'Analysis Dashboard'
    },
    {
      moduleName: 'users',
      displayName: 'Users'
    }
  ]

  selectedPlace: string;

  constructor(public router: Router, private commonService: CommonService) { }

  ngOnInit() {
    let routeArr = this.router.url.split('/');
    this.selectedPlace = routeArr[routeArr.length - 1];
    this.commonService.setCurrentPlace(this.selectedPlace);

    let userData = JSON.parse(localStorage.userInfo ? localStorage.userInfo : '{}');
    if (userData.userClass && (userData.userClass != 'ADMIN')) {
      this.places.splice(1, 1);
    }
  }

  changePlace(place: any) {
    this.router.navigate(['dashboard/' + place]);
    this.selectedPlace = place;
    this.commonService.setCurrentPlace(this.selectedPlace);
  }

  getCurrentPlace() {
    let routeArr = this.router.url.split('/');
    return routeArr[routeArr.length - 1];
  }

}
