import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalysisboardComponent } from './analysisboard/analysisboard.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(x => x.LoginModule)
  },
  {
    path: 'dashboard',
    component: AnalysisboardComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./users-board/users-board.module').then(m => m.UsersBoardModule)
      },
      {
        path: 'analysis',
        loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
