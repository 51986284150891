import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-create-user-popup',
  templateUrl: './create-user-popup.component.html',
  styleUrls: ['./create-user-popup.component.less']
})
export class CreateUserPopupComponent implements OnInit {
  createUserData: any = {
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    purpose: '',
    password: '',
    role: '',
  }

  constructor(public dialogRef: MatDialogRef<CreateUserPopupComponent>, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  createUser() {
    this.commonService.createUser(this.createUserData).subscribe((res: any) => {
      // localStorage.sessionToken = res.data.sessionToken;
      this.commonService.reloadUserList(true);
      this.dialogRef.close();
    });
  }

  closeUserDialog() {
    this.dialogRef.close();
  }

}
