import { IconService } from './icons-setting/Icons.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Analysis Project';

  constructor(private iconService:IconService) {
    this.iconService.registerIcons()
  }
}
