<div class="analysisBoardMainDiv">
    <app-header></app-header>
    <div class="analysisBoardBody">
        <div class="sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="contenttab">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>