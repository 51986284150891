import { HttpClient, HttpBackend } from '@angular/common/http/';
import { Injectable } from '@angular/core';
import { Config } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  config: Config;

  constructor(private http: HttpBackend) { }

  loadAppConfig() {
    return new HttpClient(this.http)
          .get<Config>('./assets/appConfig/appConfig.json')
          .toPromise()
          .then(config => {
            this.config = config;
          });
  }
}
