import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analysisboard',
  templateUrl: './analysisboard.component.html',
  styleUrls: ['./analysisboard.component.less']
})
export class AnalysisboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
